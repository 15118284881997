import ApplyJobForm from "./apply-job"
import { createModal, applyTypes } from "./form"
import Success from "./success"
import axios from "axios"
import React from "react"
import { API_HOST, API_SEND_EMAIL, API_UPLOAD, API_HOST_SPRING } from "../../../config"

export const errorHandler = (intl, e) => {
  alert(intl.formatMessage({ id: "error.requestFailed" }))
}

export const $post = (url, data) => axios.post(`${API_HOST}${url}`, data)
export const $get = url => axios.get(`${API_HOST}${url}`)
export const $getLocation = (url) => axios.get(`${API_HOST_SPRING}${url}`)


export const showJobApplyForm = ({ intl, jobId, title = "ApplyJob", type = applyTypes.apply_job, notifies = null }) => {
  createModal(<ApplyJobForm />, {
    intl,
    title,
    type,
    onSubmit(data, closeWindow) {
      const {jobId, CoverLetter, Resume} = data
      console.log("data::", data)
      axios
        .post(`${API_HOST}/submit-applies`, data)
        .then(data => {
          if (data.status === 200) {
            closeWindow()
            createModal(<Success />, { intl, title: "success" })
          } else {
            console.log(data)
          }
        })
        .catch(e => {
          errorHandler(intl, e)
        })
      axios
        .post(API_SEND_EMAIL, {...data, notifies, CoverLetter: CoverLetter ? `${API_UPLOAD}${CoverLetter}` : '', Resume: `${API_UPLOAD}${Resume}`})
        .catch(e => {
          errorHandler(intl, e)
        })
    },
  })
}
