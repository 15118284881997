import React, { useContext, useState } from "react"
import classNames from "classnames"
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl"
import "./language.sass"
import { globalContext } from "./context"

const Language = () => {
  const [actived, setActived] = useState(false)
  const { country } = useContext(globalContext)

  // 修改语言
  const onchangeLocale = lan => {
    changeLocale(lan)
    setActived(false)
  }

  const languageName = {
    en: "English",
    zh: "中文",
    fr: "Français",
  }

  return (
    <div className="navbar-item language language-container">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => {
          if (country !== "ca") {
            const index = languages.findIndex(item => item === "fr")
            if (index > -1) {
              languages.splice(index, 1)
            }
            if (currentLocale === "fr") {
              changeLocale("en")
            }
          }
          return (
            <div>
              <div
                className={classNames("text-container", { active: actived })}
                onClick={() => {
                  setActived(!actived)
                }}
              >
                {languageName[currentLocale]}
                <i className="arrow" />
              </div>
              <div className={classNames("dropdown-menu", { active: actived })}>
                {languages.map(lan => {
                  return (
                    <div className="dropdown-item" key={lan} onClick={() => onchangeLocale(lan)}>
                      {languageName[lan]}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }}
      </IntlContextConsumer>
    </div>
  )
}

export default Language
