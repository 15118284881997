import React, { useEffect, useLayoutEffect, useState } from "react"
import { defaultIntl } from "../../utils"
import { API_HOST } from "../../../config"
import axios from "axios"
import { errorHandler } from "."
import { Select } from 'antd'
import 'antd/dist/antd.css';

const { Option } = Select
export default ({ intl = defaultIntl }) => {
  const [jobTitle, setJobTitle] = useState("")
  const [desArea, setDesArea] = useState("")
  const [cities, setCities] = useState([])
  const [CoverLetter, setCoverLetter] = useState("")
  const [Resume, setResume] = useState("")

  useLayoutEffect(()=>{
    const _title = document.getElementById("job-title")
    const _city = document.getElementById("job-location")
    if (_title) {
      setJobTitle(_title.innerText)
      console.log(_title.innerText)
    }
    if (_city) {
      setCities(_city.innerText.split(','))
    }
  },[]);

  const uploadFile = (e, type="resume") => {
    const file = e.target.files[0]
    const data = new FormData()
    const _title = document.getElementById("job-title")
    const _city = document.getElementById("job-location")
    data.append("data", JSON.stringify({ file: null }))
    data.append("files.file", file)
    console.log(_title, _city)
    if (_title) {
      setJobTitle(_title.innerText)
      console.log(_title.innerText)
    }
    return axios
      .post(`${API_HOST}/upload-files`, data, {})
      .then(data => {
        if (data.status === 200) {
          if(type==='resume'){
            setResume(data.data.file.url)
          } else{
            setCoverLetter(data.data.file.url)
          }
        } else {
          errorHandler(intl)
        }
      })
      .catch(e => {
        errorHandler(intl, e)
      })
  }
  return (
    <div className="form apply">
      <div className="field">
        <label className="label">{`${intl.formatMessage({ id: "form.city" })}: `}
          { 
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder={intl.formatMessage({ id: "form.selectCity" })}
              optionFilterProp="children"
              onChange={(item) => {
                setDesArea(item)}
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((city, index) => (
                <Option value={city} key={index}>{city}</Option>
              ))}
            </Select>
          }
        </label>
        <div className="field-body" style={{height:'1px',overflow:'hidden', visibility:'hidden'}}>
          <input type="text" name="Area" readOnly required value={desArea} />
          <input type="text" name="Job_title" readOnly required value={jobTitle} />
          <input type="text" name="CoverLetter" readOnly value={CoverLetter} />
          <input type="text" name="Resume" readOnly required value={Resume} />
        </div>
      </div>
      <div className="field">
        <label className="label required">{intl.formatMessage({ id: "form.name" })}</label>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded has-icons-left">
              <input className="input" name="Name" type="text" required placeholder={intl.formatMessage({ id: "form.firstname" })} />
              <span className="icon is-small is-left">
                <i className="fas fa-user" />
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control is-expanded has-icons-left has-icons-right">
              <input className="input" name="Name" type="text" required placeholder={intl.formatMessage({ id: "form.lastname" })} />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope" />
              </span>
              <span className="icon is-small is-right">
                <i className="fas fa-check" />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label required">{intl.formatMessage({ id: "form.email" })}</label>
        <div className="control has-icons-left has-icons-right">
          <input className="input" name="Email" type="phone" required placeholder={intl.formatMessage({ id: "form.email" })} />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope" />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle" />
          </span>
        </div>
      </div>
      <div className="field">
        <label className="label required">{intl.formatMessage({ id: "form.source" })}</label>
        <div className="control has-icons-left has-icons-right">
          <input className="input" name="Source" type="text" required placeholder={intl.formatMessage({ id: "form.sourcePlaceholder" })} />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope" />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle" />
          </span>
        </div>
      </div>
      <div className="field">
        <label className="label ">{intl.formatMessage({ id: "form.desc" })}</label>
        <div className="control has-icons-left has-icons-right">
          <textarea className="textarea" name="Description" required placeholder={intl.formatMessage({ id: "form.desc" })} />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope" />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle" />
          </span>
        </div>
      </div>
      <div className="field">
        <label className="label">{intl.formatMessage({ id: "form.coverLetter" })}</label>
        <div className="control has-icons-left has-icons-right">
          <input type="file" name="ignored" accept="application/pdf,.doc, .docx" onChange={e=>uploadFile(e, 'cover-letter')} />
        </div>
        <p className="has-text-grey-light">less than 3M.</p>
      </div>
      <div className="field">
        <label className="label required">{intl.formatMessage({ id: "form.resume" })}</label>
        <div className="control has-icons-left has-icons-right">
          <input type="file" name="ignored" accept="application/pdf,.doc, .docx" onChange={uploadFile} />
        </div>
        <p className="has-text-grey-light">less than 3M.</p>
      </div>
    </div>
  )
}
