import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const FontScheme = {
  en: "Open sans, Arial, Verdana, Tahoma, Trebuchet MS, Georgia",
  fr: "Open sans, Arial, Verdana, Tahoma, Trebuchet MS, Georgia",
  zh: "PingFang SC, Microsoft YaHei, SimHei",
}

function Font({ lang }) {
  return (
    <Helmet>
      <style type="text/css">{`
        html,body, pre, div, p, span {
          font-family: ${FontScheme[lang]}
        }
        h1, h2, h3, h4{
          font-weight: bold
        }
    `}</style>
    </Helmet>
  )
}

Font.defaultProps = {
  lang: "en",
}

Font.propTypes = {
  lang: PropTypes.string,
}

export default Font
