import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import "./country.sass";
import { isAu, getNowCountry } from "../utils"
import { auHref, caHref } from "../utils/constant";
import { globalContext } from "./context";

const Country = () => {
  const { intl, country, setCountry } = useContext(globalContext);
  const [actived, setActived] = useState(false);

  useEffect(() => {
    if (isAu()) {
      setCountry("au");
    } else {
      const currentCountry = getNowCountry();
      if (currentCountry) {
        setCountry(currentCountry)
      }
    }
  }, [])

  const changeCountry = (c) => {
    setCountry(c);
    setActived(false)
    if (c === 'au') {
      window.location.href = `${auHref}?country=au`;
    } else {
      window.location.href=`${caHref}?country=${c}`
    }
  }
  
  return <div className="navbar-item language country-container">
      <div className={classNames("text-container", { active: actived })} onClick={() => {
        setActived(!actived)
      }}>{intl.formatMessage({id: `header.country.${country}`})}
        <i className="arrow" />
      </div>
      <div className={classNames("dropdown-menu", {active: actived})} >
        <a className="dropdown-item" onClick={() => changeCountry('ca')} >
          {intl.formatMessage({ id: "header.country.ca" })}
        </a>
        <a className="dropdown-item" onClick={() => changeCountry('us')} >
        {intl.formatMessage({ id: "header.country.us" })}
        </a>
        <a className="dropdown-item" onClick={() => changeCountry('au')}>
        {intl.formatMessage({ id: "header.country.au" })}
        </a>
        <a className="dropdown-item" onClick={() => changeCountry("uk")}>
        {intl.formatMessage({ id: "header.country.uk" })}
        </a>
      </div>
  </div>
}

export default Country;