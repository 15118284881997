import React from "react"
import { defaultIntl } from "../../utils"

export default ({ intl = defaultIntl }) => {
  return (
    <div className="form apply">
      <h2 className="title is-h1">{intl.formatMessage({ id: "success.title" })}</h2>
      <p className="is-info">{intl.formatMessage({ id: "success.desc" })}</p>
    </div>
  )
}
