import React, { useState } from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "./header"
import Footer from "./footer"
import FONT from "./font"
import SEO from "./seo"
import classNames from "classnames"
import { defaultIntl } from "../utils"
import { globalContext } from "./context"

const Layout = ({ children, intl = defaultIntl, page }) => {

  const [country, setCountry] = useState('ca')
  
  return (
    <globalContext.Provider value={{intl, country, setCountry}}>
      <div className={classNames(`page-${page}`, { "zh-style": intl.locale === "zh" })}>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: `${page}.title` })} />
      <FONT lang={intl.locale} />
      <Header intl={intl} page={page} />
      <main className="main">{children}</main>
      <Footer intl={intl} />
    </div>
    </globalContext.Provider>
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
}

export default injectIntl(Layout)
