import React, { useEffect, useState, useRef } from "react"
import ReactDOM from "react-dom"
import classNames from "classnames"
import { document } from "../../utils"

export const applyTypes = {
  apply_advert: "apply_advert",
  apply_driver: "apply_driver",
  apply_partner: "apply_partner",
  apply_job: "apply_job",
}
const formWraper = document.createElement("div")
formWraper.classList.add("modal-wraper")
document.body.appendChild(formWraper)

export const Form = ({ children, show, onClose, intl, title, type, onSubmit }) => {
  const formRef = useRef()
  const [isShow, setShow] = useState(show)
  const [formTitle, setFormTitle] = useState("")
  const _onSubmit = e => {
    e.preventDefault()
    const Form = formRef.current
    const data = {}
    for (let i = 0; i < Form.length; i++) {
      const f = Form[i]
      if (f.tagName === "BUTTON" || f.name === "ignored") {
        continue
      }
      if (!f.value && f.required) {
        f.classList.add("is-danger")
        setTimeout(() => {
          f.classList.remove("is-danger")
        }, 4000)
        alert(intl.formatMessage({ id: "error.fieldRequired" }))
        return
      }
      if(!f.name) continue
      if (data[f.name] === undefined) {
        data[f.name] = ""
      } else {
        data[f.name] += ", "
      }
      data[f.name] += f.value
    }
    onSubmit(data, () => setShow(false))
  }
  useEffect(() => {
    if (!isShow && onClose) {
      onClose()
    }
    setFormTitle(intl.formatMessage({ id: `form.${title}.title` }))
  }, [isShow])
  return (
    <form onSubmit={_onSubmit} ref={formRef}>
      <div className={classNames("modal-background delay-1s faster", { fadeIn: isShow })} />
      <div className={classNames("modal", { modalShow: isShow })}>
        <div className={classNames("modal-card animated faster", { zoomInUp: isShow })}>
          <header className="modal-card-head">
            <p className="modal-card-title">{formTitle}</p>
          </header>
          <section className="modal-card-body">{React.cloneElement(children, { intl })}</section>
          <input type="text" required name="Apply_type" onChange={()=>{}} value={type} style={{height:'1px',opacity: 0}} />
          <footer className="modal-card-foot is-center">
            {title === "success" ? (
              <button
                className="button"
                onClick={e => {
                  e.preventDefault()
                  setShow(false)
                }}
              >
                {intl.formatMessage({ id: "form.btn3" })}
              </button>
            ) : (
              <>
                <button className="button is-success" type="submit">
                  {intl.formatMessage({ id: "form.btn1" })}
                </button>
                <button
                  className="button"
                  onClick={e => {
                    e.preventDefault()
                    setShow(false)
                  }}
                >
                  {intl.formatMessage({ id: "form.btn2" })}
                </button>
              </>
            )}
          </footer>
        </div>
      </div>
    </form>
  )
}

function destroy() {
  ReactDOM.unmountComponentAtNode(formWraper)
}

export function createModal(reactDom, { intl, title, type, onSubmit }) {
  if (type !== undefined && applyTypes[type] === undefined) {
    throw new Error("apply type does not exist!")
  }
  if (formWraper.hasChildNodes()) {
    destroy()
  }
  ReactDOM.render(<Form intl={intl} title={title} type={type} show={true} children={reactDom} onSubmit={onSubmit} onClose={destroy} />, formWraper)
}
