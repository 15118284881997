import React from "react"
import "./footer.sass"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { window } from "browser-monads"
import { getEmail } from "../utils"
import record from "../images/record.png"

const Footer = ({ intl }) => {
    const origin = window.location.origin
    const urlParams = new URLSearchParams(window.location.search)
    const country = urlParams.get("country")
    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const urlParams = new URLSearchParams(window.location.search)
        const country = urlParams.get("country")

        location.href=`${origin}/merchant-terms-and-conditions.html?country=${country || 'ca'}`
        console.log("country",country,window)

    }
    return (
        <footer className="footer">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-three-fifths">
                        <div className="columns is-mobile">
                            <div className="column is-one-third">
                                <ul className="foot-link">
                                    <li>
                                        <a href="/delivery/">{intl.formatMessage({ id: "footer.fantuan" })}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="column is-one-third">
                                <ul className="foot-link">
                                    <li>
                                        <Link to="/#aboutus">{intl.formatMessage({ id: "footer.about" })}</Link>
                                    </li>
                                    <li>
                                        <Link to="/news/">{intl.formatMessage({ id: "footer.news" })}</Link>
                                    </li>
                                    <li>
                                        <Link to="/jobs/">{intl.formatMessage({ id: "footer.careers" })}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="column is-one-third">
                                <ul className="foot-link">
                                    <li>
                                        <Link to="/#contact">{intl.formatMessage({ id: "footer.contact" })}</Link>
                                    </li>
                                    <li>
                                        <a href={`${origin}/agreement.html`}>{intl.formatMessage({ id: "footer.policy" })}</a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/termsofservice.html`}>{intl.formatMessage({ id: "footer.term" })}</a>
                                    </li>
                                    <li>
                                        <a onClick={onClick} href={`${origin}/merchant-terms-and-conditions.html?country=${country || 'ca'}`}>{intl.formatMessage({ id: "footer.merchant" })}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="column is-fifth">
                        <div className="columns is-centered">
                            <div className="column is-two-thirds lastcol">
                                <p className="words">{intl.formatMessage({ id: "footer.advice" })}</p>
                                <p>
                                    <a href={`mailto:${getEmail()}`}>{getEmail()}</a>
                                </p>
                                <p className="words">{intl.formatMessage({ id: "footer.employee" })}</p>
                                <p>
                                    <a href="mailto:hr@fantuan.ca">hr@fantuan.ca</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="middle is-addressRecord">
                    <p className="copyrt">Copyright © {new Date().getFullYear()} Fantuan</p>
                    <div className="record-box">
                        <img src={record} alt="备案"/>
                        <a className="record mr10"  href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011602300332" target="_blank">津公网安备12011602300332号</a>
                        <a className="record"  href="https://beian.miit.gov.cn" target="_blank">津ICP备16006108号-1</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default injectIntl(Footer)
